@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-stone-50;
  min-width: 360px;
}

img {
  -webkit-user-drag: none;
}

@font-face {
  font-family: "GapyeongHanseokbong";
  src: url("assets/GapyeongHanseokbongB.woff") format('woff');
  font-weight: bold;
}